import styled from "./upload.module.css";
import uploadIcon from "@/assets/upload.png";
import { useRef, useState } from "react";
import { isPicture, pictureToBase64 } from "@/app/util";
import LoadingIcon from "@/features/common/loading";
import { PostFile } from "../user/userAPI";

export default function Upload(props: {
  loading: boolean;
  onUpload: Function;
  title: string;
  accept: string;
  alertText?: string;
  multiple?: boolean;
  isRequired?: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState("");
  const [base64, setBase64] = useState("");

  return (
    <div
      className={styled["upload-component"]}
      onClick={() => {
        inputRef.current?.click();
      }}
    >
      <p className={styled["upload-title"]}>
        {props.title}
        {props?.isRequired && <span className={styled["red-fonts"]}>*</span>}
      </p>

      <div className={styled["picture-wrap"]}>
        {name}
        {props.loading ? (
          <div className={styled["picture-loading"]}>
            <LoadingIcon size={40} color={"#ffffff"} />
          </div>
        ) : (
          <img className={styled["picture-upload"]} src={uploadIcon} alt="" />
        )}
        {base64 != "" ? (
          <img className={styled["picture-user"]} src={base64} alt="" />
        ) : null}
        <input
          accept={props.accept}
          type="file"
          onChange={(e) => {
            const file = e.target.files;
            if (file && file.length > 0) {
              if (isPicture(file[0].type)) {
                pictureToBase64(file[0]).then((res) => {
                  if (res) setBase64(res as string);
                });
              } else {
                setName(file[0].name);
              }
              props.onUpload(file[0]);
            }
          }}
          ref={inputRef}
        />
      </div>
      <p className={styled["upload-alert"]}>{props.alertText}</p>
    </div>
  );
}
